import "swiper/swiper-bundle.min.css";
import {
  Swiper,
  Pagination,
  Navigation,
  Autoplay,
  EffectFade
} from "swiper";
import http from "@/api/axios";
export default {
  data() {
    return {
      monthSwiper: "",
      customSwiper:"",
      searchParams: {},
      paginationParams: {
        limit: 6,
        page: 1,
      },
      newsList: [],
      prodList: [],
      sildeList:[],
      sildeListEdit:[],
    };
  },
  watch:{
    //当 数据list变化是 通过setTimeout 延时一下在初始化Swiper
    sildeListEdit(){
      setTimeout(()=>{
        this.customSwiper = new Swiper(".customers-swiper-container", {
          loop: true,
          autoplay: {
            delay: 4000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".customers-swiper-button-next",
            prevEl: ".customers-swiper-button-prev",
          },
        });
      },0)
    }

  },
  mounted() {
    this.initSwiper();
    this.getData();
  },
  methods: {
    initSwiper() {
      //swiper提供了use方法，其他写法没有啥区别和swiper4、5一样
      Swiper.use([Pagination]);
      Swiper.use([Navigation]);
      Swiper.use([Autoplay]);
      Swiper.use([EffectFade]);
      this.monthSwiper = new Swiper(".first-swiper-container", {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        effect: 'fade',
        autoplayDisableOnInteraction: false,
        speed: 1000,
        pagination: {
          el: ".first-swiper-pagination",
          clickable: true,
        },
        on: {
          init: function () {
            let slide = this.slides.eq(0);
            slide.addClass('ani-slide');
          },
          transitionStart: function () {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i);
              slide.removeClass('ani-slide');
            }
          },
          transitionEnd: function () {
            let slide = this.slides.eq(this.activeIndex);
            slide.addClass('ani-slide');
          },
        },
      });
      // this.customSwiper = new Swiper(".customers-swiper-container", {
      //   loop: true,
      //   autoplay: {
      //     delay: 4000,
      //     stopOnLastSlide: false,
      //     disableOnInteraction: false,
      //   },
      //   navigation: {
      //     nextEl: ".customers-swiper-button-next",
      //     prevEl: ".customers-swiper-button-prev",
      //   },
      // });
    },
    getData() {
      http.get("/news/findAllWeb", {
        params: {
          ...this.paginationParams,
        },
      }).then((res) => {
        this.newsList = res.content;
      });
      http.get("/slideShow/findAllWeb", {
        params: {
          limit: 100,
        },
      }).then((res) => {
        this.sildeList = res.content;
        let len = 0;
        for(let i=0 ; i<=parseInt((this.sildeList.length)/15) ; i++) {
          if(len<=this.sildeList.length){
            this.sildeListEdit.push(this.sildeList.slice(len,len+15));
            len += 15;
          }
        }
        console.log(this.sildeListEdit);
      });
      http.get("/productIntroduction/findAllWeb", {
        params: {
          limit: 8,
          page: 1,
        },
      }).then((res) => {
        this.prodList = res.content;
        
      });
    },
    navigateTo(url,type) {
      this.$router.push({
        path: url
      });
      if(type!==undefined){
        sessionStorage.setItem('type',type);
      }
    },
    newsTo(url,index) {
      this.$router.push({
        path: url
      });
      if(index!==undefined){
        sessionStorage.setItem('index',index);
      }
    }
  },
};